import styled from 'styled-components';
import playIcon from '@src/images/icons/play-icon-white.svg';
import playIconBlack from '@src/images/icons/play-icon-black.svg';

export * from './links';
export * from './RichText';

export const PlayIcon = styled.span`
    display: inline-block;

    background-image: url(${props =>
        props.isBlack ? playIconBlack : playIcon});
    background-repeat: no-repeat;
    color: white;

    height: 12px;
    width: 12px;
    margin-left: 8px;
`;
