import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from '@components/shared/links';
import {
    LinkItem,
    LinksList,
    LinksWrapper,
    MobileHamburger,
} from './Links.styled';

export const PureLinks = ({ data }) => {
    const {
        prismic: {
            allHeaders: { edges },
        },
    } = data;
    let menu_links = [];
    if (edges.length) {
        ({ menu_links } = edges[0].node);
    }

    const [open, setOpen] = useState(false);

    if (!menu_links.length) return null;

    return (
        <LinksWrapper>
            <MobileHamburger
                open={open}
                onClick={() => {
                    setOpen(!open);
                }}
            >
                <div />
            </MobileHamburger>
            <LinksList open={open}>
                {menu_links.map(({ link, label }, i) => (
                    <LinkItem
                        key={`${i}_${label}`}
                        onClick={() => setOpen(false)}
                    >
                        <Link
                            link={link}
                            index={`${i}_${label}`}
                            partiallyActive
                        >
                            {label}
                        </Link>
                    </LinkItem>
                ))}
            </LinksList>
        </LinksWrapper>
    );
};

const Links = () => {
    const data = useStaticQuery(graphql`
        query LinksQuery {
            prismic {
                allHeaders(first: 1) {
                    edges {
                        node {
                            menu_links {
                                link {
                                    _linkType
                                    ... on PRISMIC__ExternalLink {
                                        url
                                    }
                                    ... on PRISMIC_Homepage {
                                        _linkType
                                        _meta {
                                            uid
                                        }
                                    }
                                    ... on PRISMIC_Archive_page {
                                        _linkType
                                        _meta {
                                            uid
                                        }
                                    }
                                    ... on PRISMIC_Schedulepage {
                                        _linkType
                                        _meta {
                                            uid
                                        }
                                    }
                                    ... on PRISMIC_Infopage {
                                        _linkType
                                        _meta {
                                            uid
                                        }
                                    }
                                    ... on PRISMIC_Newsoverview {
                                        _linkType
                                        _meta {
                                            uid
                                        }
                                    }
                                }
                                label
                            }
                        }
                    }
                }
            }
        }
    `);

    if (!data) {
        return null;
    }

    return <PureLinks data={data} />;
};

export default Links;
