import ApercuMonoProOtf from '@src/fonts/Apercu-Mono-Pro.otf';
import ApercuMonoProBoldOtf from '@src/fonts/Apercu-Mono-Pro-Bold.otf';
import ApercuMonoProLightOtf from '@src/fonts/Apercu-Mono-Pro-Light.otf';
import ApercuMonoProMediumOtf from '@src/fonts/Apercu-Mono-Pro-Medium.otf';
import SuisseIntlRegularOtf from '@src/fonts/SuisseIntl-Regular.otf';
import SuisseIntlRegularItalicOtf from '@src/fonts/SuisseIntl-RegularItalic.otf';
import SuisseIntlSemiBoldOtf from '@src/fonts/SuisseIntl-SemiBold.otf';

const fonts = [
    {
        name: 'ApercuMonoPro',
        src: ApercuMonoProOtf,
        weight: 'normal',
    },
    {
        name: 'ApercuMonoPro',
        src: ApercuMonoProBoldOtf,
        weight: 700,
    },
    {
        name: 'ApercuMonoPro',
        src: ApercuMonoProLightOtf,
        weight: 300,
    },
    {
        name: 'ApercuMonoPro',
        src: ApercuMonoProMediumOtf,
        weight: 500,
    },
    {
        name: 'SuisseIntl',
        src: SuisseIntlRegularOtf,
        weight: 'normal',
    },
    {
        name: 'SuisseIntl',
        src: SuisseIntlRegularItalicOtf,
        weight: 'normal',
        style: 'italic',
    },
    {
        name: 'SuisseIntl',
        src: SuisseIntlSemiBoldOtf,
        weight: 500,
    },
];

const generateFontFace = ({ name, src, weight, style = 'normal' }) =>
    `@font-face {
    font-family: "${name}";
    font-style: ${style};
    font-weight: ${weight};
    src: local("${name}"), url(${src}) format("opentype");
}`;

export default fonts.map(generateFontFace).join('\n\n');
