import colors from './colors';

export const buttonHover = (color = colors.brownGrey, bgColor = '#000000') =>
    `color: ${color};
border: 1px solid currentColor;
position: relative;
overflow: hidden;

cursor: pointer;

&,
&:before,
&:after {
    box-sizing: border-box;
    transition: all 0.5s ease-in-out;
}

&:before,
&:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
}

&:before {
    right: -50px;
    border-right: 50px solid transparent;
    border-bottom: 50px solid ${color};
    transform: translateX(-100%);
}

&:after {
    left: -50px;
    border-left: 50px solid transparent;
    border-top: 50px solid ${color};
    transform: translateX(100%);
}

&:hover {
    color: ${bgColor};

    &:before { transform: translateX(-49%); }
    &:after { transform: translateX(49%); }
}`;

export default `
    input, textarea, select, a { outline: none; }

    input[type="email"],
    input[type="text"] {
        border: 0;
        background-color: transparent;
        color: ${colors.brownGrey};

        padding-top: 3px;
        padding-bottom: 12px;
        border-bottom: 1px solid ${colors.brownGrey};

        font-family: ApercuMonoPro;
        text-transform: uppercase;
        letter-spacing: 0px;
        font-size: 15px;
        font-weight: 300;
        line-height: 1.2;
        
    }

    span.button, button[type="submit"] {
        background-color: transparent;
        position: relative;
        display: inline-block;
        padding: 12px 20px;
        overflow: hidden;
        text-transform: uppercase;
        
        font-size: 14px;
        line-height: 1.21;
        letter-spacing: 1px;
        color: ${colors.brownGrey};

        ${buttonHover()}
    }
`;
