import React, { Component } from 'react';
import { get } from 'lodash';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled, { createGlobalStyle } from 'styled-components';

import Header from '@components/header';
import SEO from '@components/header/seo';
import Footer from '@components/footer';
import reset from '@shared/reset';
import colors from '@shared/colors';
import fonts from '@shared/fonts';
import forms from '@shared/forms';
import { generateBreakpoint } from './grid';
import CookieBanner from '../components/cookie-banner';

const GlobalStyle = createGlobalStyle`
    ${reset}
    ${fonts}
    ${forms}

    main {
        padding-top: 58px;

        ${generateBreakpoint('md')('padding-top: 82px;')}
    }

    body {
        background-color: black;
        color: ${colors.white};
        font-family: 'ApercuMonoPro', Helvetica, sans-serif;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    a, a:visited, a:active {
        color: white;
        text-decoration: none;
    }
`;

const LayoutWrapper = styled.div`
    display: relative;
`;

const ContentWrapper = styled.main`
    transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.915, 0.315);
    opacity: 0;

    &.page-appear,
    &.page-enter {
        opacity: 0;
    }

    &.page-exit {
        opacity: 1;
    }
    &.page-exit-active {
        opacity: 0;
    }

    &.page-appear-done,
    &.page-enter-done {
        opacity: 1;
    }
`;

class PureContent extends Component {
    shouldComponentUpdate(nextProps) {
        const shouldUpdate = !get(
            nextProps,
            'children.props.location.state.noRefresh',
            false,
        );

        return shouldUpdate;
    }
    render() {
        const { children } = this.props;
        return <ContentWrapper>{children}</ContentWrapper>;
    }
}

const BackgroundWrapper = styled.div`
    background-color: ${props => props.bgColor || '#000'};
    transition: background-color 1s ease-out;
`;

const Layout = props => {
    const { children, pageContext } = props;
    const title = get(pageContext, 'pageInfo.title', 'Future Intel');
    const description = get(pageContext, 'pageInfo.seoDescription', '');
    const sectionTitle = get(pageContext, 'pageInfo.sectionTitle');
    const image = get(pageContext, 'pageInfo.image');
    const pageKey = get(pageContext, 'pageInfo.pageKey');
    const bgColor = get(pageContext, 'pageInfo.bgColor');
    return (
        <LayoutWrapper>
            <GlobalStyle />
            <SEO
                title={title}
                description={description}
                sectionTitle={sectionTitle}
                image={image}
            />
            <Header />
            <BackgroundWrapper bgColor={bgColor}>
                <TransitionGroup component={null}>
                    <CSSTransition
                        enter={true}
                        exit={true}
                        in
                        appear
                        timeout={500}
                        key={pageKey}
                        classNames={'page'}
                    >
                        <PureContent>{children}</PureContent>
                    </CSSTransition>
                </TransitionGroup>
            </BackgroundWrapper>
            <Footer />
            <CookieBanner />
        </LayoutWrapper>
    );
};

export default Layout;
