const React = require('react');
const { Provider } = require('react-redux');

const createStore = require('./index.js');

// eslint-disable-next-line react/display-name,react/prop-types
const wrapRootElement = ({ element }) => {
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts

    let initialState = {};
    if (typeof window !== 'undefined') {
        initialState = window.__PRELOADED_REDUX;
    }

    const store = createStore.default(initialState);
    return <Provider store={store}>{element}</Provider>;
};

module.exports = wrapRootElement;
