import React from 'react';
import styled from 'styled-components';
import {
    generateGrid,
    generateContainer,
    generateBreakpoint,
} from '@layouts/grid';
import colors from '@shared/colors';
import Logo from './Logo';
import Links from './Links';

const HeaderWrapper = styled.nav`
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: #000;
    width: 100%;
    ${generateContainer()}
    ${generateGrid()}
    align-items: center;
    height: 58px;
    padding: 0 17px;
    border-bottom: 1px solid ${colors.brownGrey};

    ${generateBreakpoint('md')(
        `height: 82px;
        padding: 0 20px;`,
    )}
`;

const Header = () => {
    return (
        <HeaderWrapper>
            <Logo />
            <Links />
        </HeaderWrapper>
    );
};

export default Header;
