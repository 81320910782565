import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { get } from 'lodash';
import { Helmet } from 'react-helmet';

const LANG = 'en';
const MAX_DESCRIPTION_LENGTH = 158;

export const PureSEO = ({ title, sectionTitle, description, image, data }) => {
    const {
        prismic: {
            allHeaders: { edges },
        },
    } = data;
    const defaultData = {};
    if (edges.length) {
        const {
            default_seo_description,
            author,
            default_title,
            default_seo_imageSharp,
            default_seo_image,
        } = edges[0].node;
        defaultData.description = default_seo_description;
        defaultData.author = author;
        defaultData.title = default_title;
        defaultData.image =
            get(default_seo_imageSharp, 'childImageSharp.fluid.src', '') ||
            default_seo_image;
    }

    const parsedTitle =
        typeof title === 'string'
            ? title
            : get(title, '[0].text', '') || defaultData.title;
    const parsedDescription =
        typeof description === 'string'
            ? description
            : get(description, '[0].text') || defaultData.description;
    let seoImage = image;
    if (!seoImage) {
        seoImage = defaultData.image;
    }
    const titleWithSection = sectionTitle
        ? `${parsedTitle} | ${sectionTitle} | ${defaultData.title}`
        : `${parsedTitle} | ${defaultData.title}`;
    const briefDescription = parsedDescription
        ? parsedDescription.substr(0, MAX_DESCRIPTION_LENGTH)
        : parsedDescription;

    return (
        <Helmet
            htmlAttributes={{
                lang: LANG,
            }}
            title={titleWithSection}
            defaultTitle={defaultData.title}
            meta={[
                {
                    name: `description`,
                    content: briefDescription,
                },
                {
                    property: `og:title`,
                    content: titleWithSection,
                },
                {
                    property: `og:description`,
                    content: briefDescription,
                },
                {
                    property: 'og:image',
                    content: seoImage,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: defaultData.author,
                },
                {
                    name: `twitter:title`,
                    content: titleWithSection,
                },
                {
                    name: `twitter:description`,
                    content: briefDescription,
                },
                {
                    name: 'twitter:image',
                    content: seoImage,
                },
                {
                    name: 'robots',
                    content: 'index, follow',
                },
            ]}
        />
    );
};

const SEO = props => {
    const data = useStaticQuery(graphql`
        query SEOQuery {
            prismic {
                allHeaders(first: 1) {
                    edges {
                        node {
                            default_seo_description
                            author
                            default_title
                            default_seo_imageSharp {
                                childImageSharp {
                                    fluid {
                                        src
                                    }
                                }
                            }
                            default_seo_image
                        }
                    }
                }
            }
        }
    `);

    if (!data) {
        return null;
    }

    return <PureSEO data={data} {...props} />;
};

export default SEO;
