import React, { useCallback, useState } from 'react';
import Cookie from 'js-cookie';
import {
    StyledCookieBanner,
    StyledCookieButton,
    StyledCookieBannerInner,
} from './index.styled';

const COOKIE_NAME = 'fi_cookies_accepted';

const CookieBanner = () => {
    const [accepted, setAccepted] = useState(!!Cookie.get(COOKIE_NAME));
    const handleAccept = useCallback(() => {
        Cookie.set(COOKIE_NAME, true, { expires: 3650 });
        setAccepted(true);
    }, []);

    if (accepted) {
        return null;
    }
    return (
        <StyledCookieBanner>
            <div className="banner-fg">
                <StyledCookieBannerInner>
                    <p>
                        To enhance your experience on our website, we use
                        cookies. In order to do so, we need your consent. Find
                        out more in our privacy statement.
                    </p>
                    <StyledCookieButton onClick={handleAccept}>
                        <span>accept</span>
                    </StyledCookieButton>
                </StyledCookieBannerInner>
            </div>
            <div className="banner-bg" />
        </StyledCookieBanner>
    );
};

export default CookieBanner;
