import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import colors from '@shared/colors';
import { generateGrid } from '@src/layouts/grid';
import {
    FooterCollapseable,
    FooterSection,
    MailingListForm,
} from './SectionBlocks';

const SectionWrapper = styled.div`
    padding-top: 34px;
    padding-bottom: 58px;
    color: ${colors.brownGrey};

    border-bottom: 1px solid ${colors.brownGrey};

    ${generateGrid()}
`;

export const PureSections = ({ data }) => {
    const {
        prismic: {
            allFooters: { edges },
        },
    } = data;

    let body = [];
    if (edges.length) {
        ({
            node: { body },
        } = edges[0]);
    }

    if (!body.length) return null;
    return (
        <SectionWrapper>
            {body.map(({ fields, primary, type }, i) => {
                switch (type) {
                    case 'footer_section':
                        return (
                            <FooterCollapseable
                                index={i}
                                title={primary.headline}
                                key={`${i}_${type}`}
                            >
                                <FooterSection
                                    fields={fields}
                                    primary={primary}
                                />
                            </FooterCollapseable>
                        );
                    case 'mailing_list_form':
                        return (
                            <FooterCollapseable
                                index={i}
                                title={primary.headline}
                                key={`${i}_${type}`}
                            >
                                <MailingListForm
                                    key={`${i}_${type}`}
                                    primary={primary}
                                />
                            </FooterCollapseable>
                        );
                    default:
                        return null;
                }
            })}
        </SectionWrapper>
    );
};

const Sections = () => {
    const data = useStaticQuery(graphql`
        query FooterSectionsQuery {
            prismic {
                allFooters(first: 1) {
                    edges {
                        node {
                            body {
                                ... on PRISMIC_FooterBodyFooter_section {
                                    type
                                    label
                                    fields {
                                        content
                                    }
                                    primary {
                                        headline
                                    }
                                }
                                ... on PRISMIC_FooterBodyMailing_list_form {
                                    type
                                    label
                                    primary {
                                        button_label
                                        form_url
                                        headline
                                        input_placeholder
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    if (!data) {
        return null;
    }

    return <PureSections data={data} />;
};

export default Sections;
