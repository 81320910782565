import { createStore, compose, combineReducers, applyMiddleware } from 'redux';
import { pick } from 'lodash';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';

import shows from './shows/reducers';
import recent from './recently-viewed/reducers';
import schedule from './schedule/reducers';

const store = (initialState = {}) => {
    const middleware = [promise, thunk];
    const reducers = combineReducers({
        shows,
        recent,
        schedule,
    });
    const composeEnhancers =
        (typeof window !== 'undefined' &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                trace: true,
                traceLimit: 25,
            })) ||
        compose;
    const enhancer = composeEnhancers(applyMiddleware(...middleware));

    const persistedState =
        typeof localStorage !== 'undefined' &&
        localStorage.getItem('reduxState')
            ? JSON.parse(localStorage.getItem('reduxState') || '')
            : {};

    const store = createStore(
        reducers,
        {
            ...initialState,
            ...persistedState,
        },
        enhancer,
    );

    if (typeof window !== 'undefined') {
        store.subscribe(() => {
            localStorage.setItem(
                'reduxState',
                JSON.stringify({
                    ...pick(store.getState(), ['recent']),
                }),
            );
        });
    }

    return store;
};

export default store;
