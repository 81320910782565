import { UPDATE_RECENTLY_VIEWED, UPDATE_ISOPEN } from './constants';

const initialState = {
    items: [],
    isOpen: false,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case UPDATE_RECENTLY_VIEWED:
            return updateRecentlyViewed(state, action);
        case UPDATE_ISOPEN:
            return updateIsOpen(state, action);
        default:
            return state;
    }
};

const updateRecentlyViewed = (state, action) => {
    return {
        ...state,
        items: action.payload,
    };
};

const updateIsOpen = (state, action) => {
    return {
        ...state,
        isOpen: action.payload,
    };
};
