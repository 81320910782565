import styled from 'styled-components';
import colors from '@shared/colors';
import { generateCol, generateBreakpoint } from '@src/layouts/grid';

import collapseIconDown from '@src/images/icons/footer-collapse-down.svg';
import fbIcon from '@src/images/icons/social-facebook-icon.svg';
import isIcon from '@src/images/icons/social-insta-icon.svg';
import scIcon from '@src/images/icons/social-soundcloud-icon.svg';

export const StyledCollapseable = styled.div`
    position: relative;
    ${generateCol(12)}

    @media screen and (min-width: 550px) {
        ${generateCol(6)}
    }

    ${generateBreakpoint('md')(generateCol(3))}

    > div:first-child {
        outline: none;
    }

    > div:first-child > h3 {
        position: relative;
        font-size: 15px;
        line-height: 1.27;

        color: ${colors.mauve};
        text-transform: uppercase;

        margin-bottom: 30px;

        cursor: pointer;

        &::after {
            display: block;
            position: absolute;
            right: 0;
            top: 5px;
            content: '';
            height: 7px;
            width: 16px;
            background-image: url(${collapseIconDown});
            background-repeat: no-repeat;
            background-size: contain;
            color: ${colors.mauve};
            transition: transform 0.15s ease;

            ${props => (props.isOpen ? 'transform: rotate(-180deg);' : '')}
        }

        ${generateBreakpoint('md')(
            `cursor: initial;
            &::after {
                display: none;
            }`,
        )}
    }

    > div:last-child {
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.25s ease-out;

        ${props =>
            props.isOpen
                ? `
            max-height: 50vh;
            padding-bottom: 32px;
        `
                : ''}

        ${generateBreakpoint('md')(`max-height: initial;`)}
    }
`;

export const StyledFooterSection = styled.div`
    a,
    a:visited,
    a:active,
    p {
        font-family: 'SuisseIntl';
        font-size: 14px;
        line-height: 1.71;
        letter-spacing: 0.28px;
        color: ${colors.brownGrey};
    }

    a:hover {
        color: rgba(255, 255, 255, 0.8);
    }

    .button a {
        transition: all 0.5s ease-in-out;
    }

    .button:hover a {
        color: black;
    }

    p {
        max-width: 70%;
    }

    p:nth-child(n + 2) {
        margin-top: 28px;
    }

    .insta-icon,
    .fb-icon,
    .sc-icon {
        display: inline-block;
        height: 22px;
        width: 22px;
        color: transparent;
        background-repeat: no-repeat;
        background-size: contain;
        vertical-align: middle;
        margin-right: 12px;
        font-size: 0;

        a,
        a:visited,
        a:active {
            color: transparent;
            font-size: 0;
            display: inline-block;
            height: 100%;
            width: 100%;
        }
    }

    .fb-icon {
        background-image: url(${fbIcon});
    }

    .insta-icon {
        background-image: url(${isIcon});
    }

    .sc-icon {
        width: 32px;
        background-image: url(${scIcon});
    }

    ${generateBreakpoint('md')('min-height: 130px;')}
`;

export const StyledMailingListForm = styled.div`
    form {
        input[type='email'],
        input[type='text'] {
            display: block;
            width: 100%;

            &:invalid {
                & + .error-helper {
                    opacity: 1;
                }
            }
        }

        .error-helper,
        .success-helper {
            margin-top: 6px;
            font-size: 10px;
            text-transform: uppercase;
        }

        .error-helper {
            opacity: 0;
        }

        button[type='submit'] {
            margin-top: 16px;
        }
    }

    ${generateBreakpoint('md')('min-height: 130px;')}
`;
