import { UPDATE_ACTIVE_SLUG, UPDATE_ACTIVE_INDEX } from './constants';

const initialState = {
    activeSlug: '',
    activeIndex: -2,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case UPDATE_ACTIVE_SLUG:
            return updateRecentlyViewed(state, action);
        case UPDATE_ACTIVE_INDEX:
            return updateIsOpen(state, action);
        default:
            return state;
    }
};

const updateRecentlyViewed = (state, action) => {
    return {
        ...state,
        activeSlug: action.payload,
    };
};

const updateIsOpen = (state, action) => {
    return {
        ...state,
        activeIndex: action.payload,
    };
};
