import styled from 'styled-components';
import colors from '@shared/colors';
import { generateBreakpoint } from '@layouts/grid';

export const LinksWrapper = styled.div`
    padding: 20px 0;
    ${generateBreakpoint('md')(`
        padding: 22px 0;
    `)};
`;

export const LinksList = styled.ul`
    margin: 0;
    list-style: none;
`;

export const LinkItem = styled.li`
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 2;
    letter-spacing: 0.14px;
    font-weight: 300;
    color: ${colors.brownGrey};
    text-align: center;

    a,
    a:visited,
    a:active {
        color: ${colors.brownGrey};

        &:hover {
            color: ;
        }
    }

    ${generateBreakpoint('md')(`
        display: inline-block;
        text-align: left;
        margin: 0;
        font-size: 14px;
        line-height: 1.21;
        letter-spacing: 0.14px;

        :nth-child(n + 2) {
            margin-left: 10px;
            &::before {
                content: '-';
                margin-right: 10px;
            }
        }
    `)}
`;
