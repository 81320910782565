const colors = {
    black: '#1d1d1b',
    mauve: '#b2688a',
    white: '#ffffff',
    mediumPink: '#f861a9',
    veryLightPink: '#d9d9d9',
    deepOrange: '#e55503',
    violetBlue: '#440ed8',
    pineGreen: '#0e4c2e',
    brownGrey: '#969696',
};

module.exports = colors;
