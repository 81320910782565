import React from 'react';
import styled from 'styled-components';
import Links from './Links';
import Sections from './Sections';
import colors from '@shared/colors';
import { generateContainer } from '@layouts/grid';

const FooterWrapper = styled.div`
    ${generateContainer()}

    border-top: 1px solid ${colors.brownGrey};
`;

const Footer = () => {
    return (
        <FooterWrapper>
            <Sections />
            <Links />
        </FooterWrapper>
    );
};

export default Footer;
