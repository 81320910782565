import React from 'react';
import styled from 'styled-components';
import { generateBreakpoint, generateCol } from '@layouts/grid';
import logo from '@src/images/icons/future-intel-logo.svg';
import logoMobile from '@src/images/icons/future-intel-logo-mobile.svg';
import { Link } from 'gatsby';

const StyledLogo = styled.div`
    position: relative;
    z-index: 102;
    ${generateCol(4)}
    a {
        display: block;
        height: 100%;
    }

    width: 81px;
    height: 29px;

    ${generateBreakpoint('md')(`
        width: 102px;
        height: 30px;
    `)}
`;

const DesktopLogo = styled.div`
    display: none;
    ${generateBreakpoint('md')('display: block;')}
    width: 100%;
    height: 100%;

    background-image: url(${logo});
    background-repeat: no-repeat;
    background-size: contain;
`;

const MobileLogo = styled.div`
    ${generateBreakpoint('md')('display: none;')}

    background-image: url(${logoMobile});
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
`;

const Logo = () => {
    return (
        <StyledLogo>
            <Link to={'/'}>
                <MobileLogo />
                <DesktopLogo />
            </Link>
        </StyledLogo>
    );
};

export default Logo;
