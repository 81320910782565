const { registerLinkResolver } = require('gatsby-source-prismic-graphql');
const { linkResolver } = require('./src/utils/linkResolver');
const wrapWithProvider = require('./src/store/wrapper');
const get = require('lodash/get');

const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => {
    const noScroll = get(location, 'state.noScroll', false);
    if (!noScroll) {
        window.scrollTo(0, 0);
    }

    return false;
};

const onInitialClientRender = () => {
    if (process.env.BUILD_STAGE !== 'build-javascript') {
        return;
    }

    // Remove the server-side injected state.
    const preloadedStateEl = document.getElementById('redux-preload');
    if (preloadedStateEl) {
        preloadedStateEl.parentNode.removeChild(preloadedStateEl);
    }
};

registerLinkResolver(linkResolver);

module.exports = {
    shouldUpdateScroll,
    wrapRootElement: wrapWithProvider,
    onInitialClientRender,
};
