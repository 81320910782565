import { schema } from 'normalizr';

export const SORT_TYPE_TITLE = 'title';
export const SORT_TYPE_DATE = 'date';
export const ORDER_DESC = 'desc';
export const ORDER_ASC = 'asc';

export const UPDATE_SORT = 'UPDATE_SORT';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const UPDATE_ACTIVE = 'UPDATE_ACTIVE';
export const UPDATE_CURRENT = 'UPDATE_CURRENT';
export const UPDATE_PAGE = 'UPDATE_PAGE';

export const SEED_INITIAL = 'SEED_INITIAL';
export const FETCH_ARCHIVED = 'FETCH_ARCHIVED';

export const ITEMS_PER_PAGE = 15;

export const tagSchema = new schema.Entity('tag');

export const showSchema = new schema.Entity(
    'show',
    {
        tags: [tagSchema],
    },
    {
        idAttribute: value => value.uid,
    },
);
