import React from 'react';
import { Link as GLink } from 'gatsby';
import { Link as PrismicLink } from 'prismic-reactjs';
import { linkResolver } from '@src/utils/linkResolver';

export const Link = ({ link, index, partiallyActive, children }) => {
    let url = PrismicLink.url(link, linkResolver);
    if (url === '' && link) {
        url = linkResolver(link);
    }

    if (!link) {
        return <span key={index}>{children}</span>;
    }

    if (
        link.link_type === 'Document' ||
        link._linkType === 'Link.document' ||
        url.charAt(0) === '/'
    ) {
        const partiallyActiveNotHome =
            partiallyActive && link._meta.uid !== 'home';
        return (
            <GLink
                activeClassName="active"
                partiallyActive={partiallyActiveNotHome}
                to={url}
                key={index}
            >
                {children}
            </GLink>
        );
    } else {
        const target = link.target
            ? { target: link.target, rel: 'noopener' }
            : {};
        return (
            <a href={url} {...target} key={index}>
                {children}
            </a>
        );
    }
};

export default Link;
