// In src/prismic-configuration.js
export const linkResolver = doc => {
    if (doc._meta) {
        /** GraphQL types resolver */
        switch (doc.__typename) {
            // case 'PRISMIC_Art':
            case 'PRISMIC_News':
            case 'PRISMIC_Event':
                return `/news/${doc._meta.uid}`;
            case 'PRISMIC_Page':
            case 'PRISMIC_Infopage':
            case 'PRISMIC_Archive_page':
            case 'PRISMIC_Newsoverview':
            case 'PRISMIC_Schedulepage':
                return `/${doc._meta.uid}`;
            case 'PRISMIC_Show':
                return `/archive/${doc._meta.uid}`;
            case 'PRISMIC_Homepage':
                return '/';
            default:
        }
    }

    /** Default Prismic resolver */
    switch (doc.type) {
        // case 'art':
        case 'news':
        case 'event':
            return `/news/${doc.uid}`;
        case 'page':
        case 'infopage':
        case 'archive_page':
        case 'newsoverview':
        case 'schedulepage':
            return `/${doc.uid}`;
        case 'show':
            return `/archive/${doc.uid}`;
        case 'homepage':
            return '/';
        default:
            return `/${doc.uid}`;
    }
};
