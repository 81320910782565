import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from '@components/shared/links';

import { LinkItem, LinksList, LinksWrapper } from './Links.styled';

export const PureLinks = ({ data }) => {
    const {
        prismic: {
            allFooters: { edges },
        },
    } = data;
    let footer_links = [];
    if (edges.length) {
        ({ footer_links } = edges[0].node);
    }

    if (!footer_links.length) return null;

    return (
        <LinksWrapper>
            <LinksList>
                {footer_links.map(({ link, label }, i) => (
                    <LinkItem key={`${i}_${label}`}>
                        <Link link={link} index={`${i}_${label}`}>
                            {label}
                        </Link>
                    </LinkItem>
                ))}
            </LinksList>
        </LinksWrapper>
    );
};

const Links = () => {
    const data = useStaticQuery(graphql`
        query FooterQuery {
            prismic {
                allFooters(first: 1) {
                    edges {
                        node {
                            footer_links {
                                link {
                                    _linkType
                                    ... on PRISMIC__ExternalLink {
                                        url
                                    }
                                    ... on PRISMIC_Page {
                                        _linkType
                                        _meta {
                                            uid
                                        }
                                    }
                                    ... on PRISMIC_Homepage {
                                        _linkType
                                        _meta {
                                            uid
                                        }
                                    }
                                    ... on PRISMIC_Archive_page {
                                        _linkType
                                        _meta {
                                            uid
                                        }
                                    }
                                    ... on PRISMIC_Schedulepage {
                                        _linkType
                                        _meta {
                                            uid
                                        }
                                    }
                                    ... on PRISMIC_Infopage {
                                        _linkType
                                        _meta {
                                            uid
                                        }
                                    }
                                    ... on PRISMIC_Newsoverview {
                                        _linkType
                                        _meta {
                                            uid
                                        }
                                    }
                                }
                                label
                            }
                        }
                    }
                }
            }
        }
    `);

    if (!data) {
        return null;
    }

    return <PureLinks data={data} />;
};

export default Links;
