import React, { useRef, useState } from 'react';
import axios from 'axios';
import { get } from 'lodash';
import { RichText } from '@components/shared';
import {
    StyledFooterSection,
    StyledMailingListForm,
    StyledCollapseable,
} from './SectionBlocks.styled';

export const FooterCollapseable = ({ title, children, index }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <StyledCollapseable isOpen={isOpen}>
            <div
                onClick={() => setIsOpen(!isOpen)}
                onKeyDown={() => setIsOpen(!isOpen)}
                tabIndex={index}
                role="button"
                aria-pressed="false"
            >
                <h3>{get(title, '[0].text', '')}</h3>
            </div>
            <div>{children}</div>
        </StyledCollapseable>
    );
};

export const FooterSection = ({ primary, fields }) => {
    if (!primary || !primary.headline || !fields) {
        return null;
    }

    return (
        <StyledFooterSection>
            {fields.map(({ content }, i) =>
                content ? <RichText key={`rt_${i}`} render={content} /> : null,
            )}
        </StyledFooterSection>
    );
};

export const MailingListForm = ({ primary }) => {
    const inputRef = useRef(null);
    const [success, setSuccess] = useState(false);
    const onSubmit = e => {
        e.preventDefault();

        const formData = new FormData(e.target);
        if (!formData.get('EMAIL')) {
            return;
        }

        axios(primary.form_url, {
            method: 'POST',
            data: formData,
            mode: 'no-cors',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).finally(() => {
            setSuccess(true);
        });
    };

    if (!primary || !primary.headline) {
        return null;
    }

    return (
        <StyledMailingListForm>
            <form onSubmit={onSubmit} ref={inputRef}>
                <input
                    type="email"
                    name="EMAIL"
                    placeholder={primary.input_placeholder}
                />
                {success ? (
                    <div className="success-helper">thank you!</div>
                ) : (
                    <div className="error-helper">invalid email address</div>
                )}
                <button type="submit">{primary.button_label}</button>
            </form>
        </StyledMailingListForm>
    );
};
