// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-data-index-js": () => import("./../src/data/index.js" /* webpackChunkName: "component---src-data-index-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-archive-js": () => import("./../src/templates/archive.js" /* webpackChunkName: "component---src-templates-archive-js" */),
  "component---src-templates-default-js": () => import("./../src/templates/default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-templates-home-js": () => import("./../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-info-js": () => import("./../src/templates/info.js" /* webpackChunkName: "component---src-templates-info-js" */),
  "component---src-templates-news-article-js": () => import("./../src/templates/news/article.js" /* webpackChunkName: "component---src-templates-news-article-js" */),
  "component---src-templates-news-event-js": () => import("./../src/templates/news/event.js" /* webpackChunkName: "component---src-templates-news-event-js" */),
  "component---src-templates-news-index-js": () => import("./../src/templates/news/index.js" /* webpackChunkName: "component---src-templates-news-index-js" */),
  "component---src-templates-schedule-js": () => import("./../src/templates/schedule.js" /* webpackChunkName: "component---src-templates-schedule-js" */)
}

