import styled from 'styled-components';
import { generateCol, generateBreakpoint } from '@layouts/grid';
import colors from '@shared/colors';
import anchorsHover from '@shared/anchors-hover';

export const MobileHamburger = styled.div`
    position: absolute;
    top: 0px;
    right: 0;
    margin: 0.8em;
    width: 24px;

    &::after,
    &::before,
    > div {
        background-color: ${colors.veryLightPink};
        border-radius: 3px;
        content: '';
        display: block;
        height: 1px;
        margin: 6px 0;
        transition: all 0.25s ease-in-out;
    }

    ${props =>
        props.open
            ? `&::before {
        transform: translateY(7px) rotate(135deg);
    }

    &::after {
        transform: translateY(-7px) rotate(-135deg);
    }

    > div {
        transform: scale(0);
    }`
            : ''}

    ${generateBreakpoint('md')(`display: none;`)}
`;

export const LinksWrapper = styled.div`
    position: absolute;
    z-index: 101;
    top: 0;
    left: 0;
    width: 100%;
    background-color: black;

    ${generateBreakpoint('md')(
        `position: relative;
        margin-top: 0;
        width: auto;
        top: initial;
        left: initial;
        ${generateCol(6, 7)}
        `,
    )}
`;

export const LinksList = styled.ul`
    margin: 0;
    list-style: none;
    max-height: 0;
    overflow: hidden;

    transition: all 0.2s ease-out;
    padding-bottom: 0;

    ${props =>
        props.open
            ? `padding-top: 58px;
            max-height: 100vh;
            height: 100vh;
    border-bottom: 1px solid ${colors.brownGrey};`
            : ''}

    ${generateBreakpoint('md')(
        `padding-bottom: 0;
        border-bottom: none;
        max-height: initial;`,
    )}
`;

export const LinkItem = styled.li`
    display: block;
    text-transform: uppercase;
    font-size: 60px;
    line-height: 1.2;
    letter-spacing: -1.8px;
    text-align: right;
    padding-left: 12px;
    padding-right: 12px;

    &,
    a,
    a:active,
    a:visited {
        color: ${colors.veryLightPink};
    }
    border-bottom: 1px solid ${colors.brownGrey};

    :first-child {
        padding-top: 31px;
    }

    ${generateBreakpoint('md')(
        `display: inline-block;
    font-size: 14px;
    line-height: 1.21;
    letter-spacing: 0.14px;
    font-weight: 300;
    color: white;

    padding: 0;
    text-align: left;
    border-bottom: 0;

    margin-left: 18px;
    :first-child {
        padding-top: 0;
        margin-left: 0;
    }

    &,
    a,
    a:active,
    a:visited {
        color: #fff;
    }

    ${anchorsHover}

    a.active {
        border-bottom: 1px solid #fff;
    }`,
    )}

    ${generateBreakpoint('lg')('margin-left: 35px;')}
`;
