import { sortBy } from 'lodash';
import {
    SORT_TYPE_DATE,
    ORDER_DESC,
    UPDATE_SORT,
    UPDATE_FILTER,
    UPDATE_ACTIVE,
    UPDATE_CURRENT,
    UPDATE_PAGE,
    SEED_INITIAL,
    FETCH_ARCHIVED,
} from './constants';

const initialState = {
    all: {},
    active: '',
    current: [],
    hasInitial: false,
    archived: [],
    page: 0,
    tags: [],
    filter: {
        query: '',
        tags: [],
    },
    sort: {
        type: SORT_TYPE_DATE,
        direction: ORDER_DESC,
    },
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SEED_INITIAL:
            return reduceInitialShows(state, action);
        case `${FETCH_ARCHIVED}_FULFILLED`:
            return reduceArchivedShows(state, action);
        case UPDATE_FILTER:
            return updateFilter(state, action);
        case UPDATE_SORT:
            return updateSort(state, action);
        case UPDATE_ACTIVE:
            return updateActive(state, action);
        case UPDATE_CURRENT:
            return updateCurrentShows(state, action);
        case UPDATE_PAGE:
            return updatePage(state, action);
        default:
            return state;
    }
};

const reduceInitialShows = (state, action) => {
    return {
        ...state,
        all: {
            ...state.all,
            ...action.payload.shows.entities.show,
        },
        current: action.payload.shows.result,
        active: action.payload.activeSlug,
        page: 0,
        hasInitial: true,
    };
};

const reduceArchivedShows = (state, action) => {
    return {
        ...state,
        all: {
            ...state.all,
            ...action.payload.entities.show,
        },
        tags: sortBy(action.payload.entities.tag, s => s.length),
        archived: action.payload.result,
    };
};

const updateFilter = (state, action) => {
    return {
        ...state,
        filter: action.payload,
    };
};

const updateActive = (state, action) => {
    return {
        ...state,
        active: action.payload,
    };
};

const updateSort = (state, action) => {
    return {
        ...state,
        sort: action.payload,
    };
};

const updateCurrentShows = (state, action) => {
    return {
        ...state,
        current: action.payload,
    };
};

const updatePage = (state, action) => {
    return {
        ...state,
        page: action.payload,
    };
};
