import styled from 'styled-components';
import colors from '@shared/colors';
import { generateBreakpoint } from '../../layouts/grid';

export const StyledCookieBanner = styled.div`
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1000;
    width: 100%;
    height: 191px;
    max-width: 504px;

    ${generateBreakpoint('md')(`height: 211px;`)}

    div.banner-fg {
        position: absolute;
        z-index: 1001;
        padding: 11px;
        top: 0;
        left: 0;
        height: calc(100% - 20px);
        width: calc(100% - 20px);
        background-color: ${colors.black};
    }

    div.banner-bg {
        position: absolute;
        bottom: 0;
        right: 0;
        height: calc(100% - 20px);
        width: calc(100% - 20px);
        background-color: black;
    }
`;

export const StyledCookieBannerInner = styled.div`
    position: relative;
    border: 1px solid ${colors.brownGrey};
    padding: 17px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 1001;
    background-color: ${colors.black};

    > p {
        color: ${colors.veryLightPink};
        font-size: 10px;
        line-height: 1.6;

        ${generateBreakpoint('md')(
            `font-size: 14px;
            line-height: 1.71;`,
        )}
    }
`;

export const StyledCookieButton = styled.div`
    display: inline-block;
    position: relative;
    background-color: ${colors.pineGreen};
    padding: 12px 20px;
    text-transform: uppercase;

    font-size: 10px;
    line-height: 1.21;
    letter-spacing: 1px;
    z-index: 1001;
    > span {
        position: relative;
        z-index: 1002;
    }

    box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 1);

    ${generateBreakpoint('md')(
        `font-size: 14px;
        line-height: 1.21;
        box-shadow: 8px 8px 0px 0px rgba(0,0,0,1);`,
    )}
    color: #ffffff;

    cursor: pointer;
`;

StyledCookieButton.displayName = 'StyledCookieButton';
