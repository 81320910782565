import React from 'react';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { linkResolver } from '../../utils/linkResolver';
import htmlSerializer from '../../utils/htmlSerializer';

export const RichText = props => (
    <PrismicRichText
        {...props}
        linkResolver={linkResolver}
        htmlSerializer={htmlSerializer}
    />
);
