import styled from 'styled-components';

export const MAX_WIDTH = 1440;
export const GUTTER_WIDTH = 16;
export const NUM_COLS = 12;

export const SM_BREAKPOINT = 400;
export const MD_BREAKPOINT = 700;
export const LG_BREAKPOINT = 1024;
export const LG_XL_BREAKPOINT = 1200;
export const XL_BREAKPOINT = 1440;
export const XXL_BREAKPOINT = 1680;

export const BREAKPOINTS = {
    xs: 0,
    sm: SM_BREAKPOINT,
    md: MD_BREAKPOINT,
    lg: LG_BREAKPOINT,
    lg_xl: LG_XL_BREAKPOINT,
    xl: XL_BREAKPOINT,
    xxl: XXL_BREAKPOINT,
};

export const generateBreakpoint = size => content =>
    `@media screen and (min-width: ${BREAKPOINTS[size]}px) {
        ${content}
    }`;

export const generateContainer = () =>
    `display: block;
margin-left: auto;
margin-right: auto;
padding-left: 12px;
padding-right: 12px;

${generateBreakpoint('md')(`
    padding-left: 20px;
    padding-right: 20px;
`)}`;

export const generateGrid = (numCols = NUM_COLS, gutterWidth = 16) => `
    display: grid;
    grid-template-columns: repeat(${numCols}, 1fr);
    column-gap: ${gutterWidth}px;
`;

export const generateCol = (col = 1, start = 0) => `
    grid-column-end: span ${col};
    ${start ? `grid-column-start: ${start};` : ''}
`;

export const Grid = styled.div`
    ${props => generateGrid(props.cols, props.gutterWidth)}
`;
